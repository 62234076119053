import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import React, { useEffect, useState } from 'react';
import { useUser } from '../../../auth/UseUser';
import EditNPI from './editRegScreens/NPI';
import { FaPrint } from 'react-icons/fa';
import NPIStruct from './editRegScreens/NPIStruct';
import SPP from './editRegScreens/SPP';
import EuRegisters from './editRegScreens/EuRegisters';
import NatRegisters from './editRegScreens/NatRegisters';
import InstitutionsInCharge from './editRegScreens/InstitutionsInCharge';
import { getNatMeasureBy, getPrintData, getRegisterCompletion } from '../../../server/server';
import HelpButton from '../../../common/HelpButton';
import HelpPageModal from '../../modals/HelpPageModal';
import { useSearchParams } from 'react-router-dom';
import NegDocs from './editRegScreens/NegDocs';
import StratDocs from './editRegScreens/StratDocs';
import { generateAndOpenPDF } from '../../../common/npi-pdf';

function EditRegister() {
  const { user, setUser } = useUser();
  const [searchParams] = useSearchParams();

  const [value, setValue] = React.useState(0);
  const [parentTitle, setParentTitle] = React.useState('');
  const [parentInstitution, setParentInstitution] = React.useState('');
  const [helpModal, setHelpModal] = useState(false);
  const [avalible, setAvalible] = useState<any>();
  const closeHelpModal = () => {
    setHelpModal(false);
  };
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  const loadM = async (id: string) => {
    const resp = await getNatMeasureBy(id);

    if (resp.status === 200) {

      setAvalible({
        1: resp?.data?.npi,
        2: resp?.data?.ssp,
        3: resp?.data?.euregister,
        6: resp?.data?.np,
        7: resp?.data?.institutions,
      });
    }
  };
  useEffect(() => {
    let eId = searchParams.get('id');
    if (eId) {
      loadM(eId);
    }
  }, []);

  const handleDownloadPDF = async () => {
    const data = await getPrintData(searchParams.get('id'));
    await generateAndOpenPDF(data.data);
  };

  return (
    <div className="py-10 px-2 overflow-auto gray-small-scroll h-full">
      <div className="flex  items-start gap-6">
        <h1 className="text-[#04438b] text-4xl font-bold">Ажурирање прописа</h1>
        <HelpButton onClick={() => setHelpModal(true)} />
      </div>
      <div className="rounded-lg border border-[#81848f3d] w-fit p-8 pb-4  mt-8 flex flex-col gap-6">
        <div className="flex justify-between items-center gap-1">
          <h1 className="text-[#04438bae] text-2xl font-bold m-0 p-0">
            {parentTitle}
          </h1>
          <p className="leading-10 text-start text-sm text-[#04438b]">
            Надлежна институција
            <span className="italic"> ( {parentInstitution} )</span>
          </p>
          <FaPrint className="text-[#04438bae] w-5 h-5 cursor-pointer" onClick={handleDownloadPDF} />
        </div>
        <Box sx={{
          width: '100%'
        }}>
          <Box sx={{
            borderBottom: 1, borderColor: 'divider'
          }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              sx={{
                '& .MuiTabs-scroller': {
                  overflowX: 'hidden',
                  '&:hover': {
                    overflowX: 'auto !important',
                  },
                },
              }}
            >
              <Tab label="Национални пропис" {...a11yProps(0)} />
              <Tab label="НПАА структура" {...a11yProps(1)} />
              <Tab
                label="ССП структура"
                {...a11yProps(2)}
                disabled={!avalible?.[1]}
              />
              <Tab
                label="Прописи ЕУ"
                {...a11yProps(3)}
                disabled={!avalible?.[2]}
              />
              <Tab
                label="Везе са преговарачким документом"
                {...a11yProps(4)}
                disabled={!avalible?.[3]}
              />
              <Tab
                label="Везе са стратешким документом"
                {...a11yProps(5)}
                disabled={!avalible?.[3]}
              />
              <Tab
                label="Везе са националним прописима"
                {...a11yProps(6)}
                disabled={!avalible?.[3]}
              />
              <Tab
                label="Институција за спровођење"
                {...a11yProps(7)}
                disabled={!avalible?.[3]}
              />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <EditNPI
              setParentTitle={setParentTitle}
              setParentInstitution={setParentInstitution}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <NPIStruct setAvalible={setAvalible} avalible={avalible} />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <SPP setAvalible={setAvalible} avalible={avalible} />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <EuRegisters setAvalible={setAvalible} avalible={avalible} />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <NegDocs />
          </TabPanel>
          <TabPanel value={value} index={5}>
            <StratDocs />
          </TabPanel>
          <TabPanel value={value} index={6}>
            <NatRegisters />
          </TabPanel>
          <TabPanel value={value} index={7}>
            <InstitutionsInCharge />
          </TabPanel>
        </Box>
      </div>
      <HelpPageModal
        modalIsOpen={helpModal}
        closeModal={closeHelpModal}
        name={'editRegister'}
      />
    </div >
  );
}

export default EditRegister;
